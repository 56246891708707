<template>
  <div @keyup.enter="getSmsLogin" class="find-password">
    <h3 class="find-password-title">
      <ArrowLeftOutlined
        @click="router.push({ name: 'login' })"
        style="margin-right: 20px; cursor: pointer; color: gray"
      />
      忘记密码
    </h3>
    <div style="text-align: right; margin-bottom: 5px"></div>
    <a-form :model="form" ref="ruleForm" :rules="rules">
      <a-form-item prop="phone">
        <a-input size="large" v-model="form.phone" prefix="手机号" />
      </a-form-item>

      <a-form-item class="smscode" prop="code">
        <a-input-group compact>
          <a-input
            v-model="form.code"
            size="large"
            placeholder="请输入您的验证码"
            style="width: calc(100% - 102px)"
          />
          <a-button
            class="get-code-btn"
            v-if="startGetCode"
            slot="addonAfter"
            disabled
            >{{ getCodeTimer }}s后重新获取</a-button
          >
          <a-button
            type="primary"
            class="get-code-btn"
            slot="addonAfter"
            @click="getSmsCode"
            v-else
            >获取验证码</a-button
          >
        </a-input-group>
      </a-form-item>
      <a-form-item>
        <a-input-password
          size="large"
          v-model="form.password"
          prefix="新密码"
        />
      </a-form-item>
      <a-form-item>
        <a-input-password size="large" v-model="verifyCode" prefix="确认密码" />
      </a-form-item>
      <a-form-item style="margin-top: 64px; margin-bottom: 16px">
        <a-button
          size="large"
          type="primary"
          @click="getSmsLogin"
          class="big-login"
          >完成，去登录</a-button
        >
      </a-form-item>
    </a-form>
  </div>
</template>
<script setup lang="ts">
import { ref, reactive } from 'vue'
import { timeFix } from '@/utils/util'
import { phoneNumRules } from '@/utils/validator'
import { ArrowLeftOutlined } from '@ant-design/icons-vue'
import type { Rule } from 'ant-design-vue/es/form'
import {
  AForm,
  AFormItem,
  AButton,
  AInput,
  AInputPassword,
  AInputGroup
} from '@/components/commons'
import { useRouter } from 'vue-router'
const router = useRouter()
const rules: Record<string, Rule[]> = {
  phone: [{ validator: phoneNumRules, trigger: 'blur' }],
  code: [{ required: true, message: '请输入验证码！', trigger: 'blur' }]
}
const form = reactive({
  phone: '',
  code: '',
  password: ''
})
const verifyCode = ref('')
const SMSLoginForm = ref('')
const startGetCode = ref(false)
const getCodeTimer = ref(60)
const timer = ref(null)
const validateStatus = ref('')
const ruleForm = ref('')
const countdown = ref(0)

function getVerificationCode() {
  // 这里可以添加发送请求获取验证码的逻辑
  // 模拟一个定时器来表示倒计时
  countdown.value = 60
  const interval = setInterval(() => {
    if (countdown.value > 0) {
      countdown.value--
    } else {
      clearInterval(interval)
    }
  }, 1000)
}
// function checkoutPhoneNumber(value) {}
async function getSmsCode() {
  let params = {
    phone: form.phone
  }
  // let res = await getLoginSmsCode(params)
  // if (res.code === 1) {
  //   this.startGetCode = true
  //   this.timer = setInterval(() => {
  //     --this.getCodeTimer
  //     if (this.getCodeTimer <= 0) {
  //       clearInterval(this.timer)
  //       this.timer = null
  //       this.startGetCode = false
  //       this.getCodeTimer = 60
  //     }
  //   }, 1000)
}
async function getSmsLogin() {
  //   ruleForm.value.validate(async (valid) => {
  //     console.log('jinlai')
  //     if (valid) {
  //       console.log(valid)
  //       let params = {
  //         phone: form.value.phone,
  //         smsCode: form.value.code,
  //         code: ''
  //       }
  //       params.code = storage.get('jdCode')
  //       // let res = await smsLogin(params)
  //       // if (res.code === 1) {
  //       //   this.$store.commit('SET_TOKEN', res.data.token)
  //       //   this.$store.commit('SET_NAME', { name: res.data.userName, welcome: '' })
  //       //   this.$store.commit('SET_AVATAR', '/avatar2.jpg')
  //       //   this.$store.commit('SET_ROLES', 'admin')
  //       //   this.$store.commit('SET_ID', res.data.id)
  //       //   this.$store.commit('SET_INFO', res.data)
  //       //   this.$router.push({ name: 'smartTools' })
  //       //   setTimeout(() => {
  //       //     this.$notification.success({
  //       //       message: '欢迎',
  //       //       description: `${timeFix()}，欢迎回来`,
  //       //     })
  //       //   }, 1000)
  //       // }
  //     }
  //   })
}
</script>

<style lang="scss">
.find-password {
  width: 100%;
  &-title {
    color: #000000d9;
    font-family: 'PingFang SC';
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
  }
}
.ant-input-group-addon {
  padding: 0;
}
.get-code-btn {
  border-radius: 0 6px 6px 0;
}
</style>
