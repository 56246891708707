import { post, get, type BaseResponse } from '@/service/http'
import type { UserRegisterUseInvitationCodeReq } from '@/types'
export function signUpBusinessAPI(data: UserRegisterUseInvitationCodeReq) {
  return post<BaseResponse<string>>(
    '/smartvend/smartvend-user/v1/user/addUser',
    data
  )
}
export function signUpUserAPI(data: UserRegisterUseInvitationCodeReq) {
  return post<BaseResponse<string>>(
    '/smartvend/smartvend-user/v1/user/register',
    data
  )
}
export function getResgisterVerifyCodeAPI(loginPhone: string) {
  return get(
    `/smartvend/smartvend-user/v1/user/findUserRegisterCode?registerPhone=${loginPhone}`
  )
}
/**审核未通过，修改信息时使用的验证码接口 */
export function resgisterEditVerifyCodeAPI(loginPhone: string) {
  return get(
    `/smartvend/smartvend-user/v1/user/findUserRefreshRegisterCode?registerPhone=${loginPhone}`
  )
}
/**审核未通过重修注册使用 */
export function refreshRegisterUerAPI(data: UserRegisterUseInvitationCodeReq) {
  return post<BaseResponse<string>>(
    '/smartvend/smartvend-user/v1/user/refreshRegisterUser',
    data
  )
}
