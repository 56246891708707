import { post, get, type BaseResponse } from '@/service/http'
import type { AccountLoginReq, UserInfo, PhoneLoginRes } from '@/types'
export function login(data: AccountLoginReq) {
  return post<BaseResponse<UserInfo>>(
    '/smartvend/smartvend-auth/v1/userAuth/login',
    data
  )
}

/** */
export function getVerifyCodeAPI(loginPhone: string) {
  return get(
    `/smartvend/smartvend-user/v1/user/findUserLoginCode
?loginPhone=${loginPhone}`
  )
}

export function phoneLoginAPI(data: PhoneLoginRes) {
  return post<BaseResponse<UserInfo>>(
    '/smartvend/smartvend-auth/v1/userAuth/phoneLogin',
    data
  )
}
