import { post, type BaseResponse } from '@/service/http'
import type { UpLoadFileRes } from '@/types'
export function uploadFileAPI(file: File) {
  return post<BaseResponse<UpLoadFileRes>>(
    '/smartvend/smartvend-capital/v1/obs/uploadFile',
    { file },
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  )
}
