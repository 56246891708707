import axios, {
  AxiosError,
  type AxiosRequestConfig,
  type AxiosResponseHeaders
} from 'axios'
//   import { nanoid } from 'nanoid';
// @ts-ignore
// import { Message } from '@chehejia/liui'
import { message } from '@/components/commons'
import { userInfoStore } from '@/stores/user'
//   import useUserStore from '@/store/user';
import router from '@/router'
//   import { generateCurl } from '@/utils/curl';
// import { Response } from '@/types'

//   const urlMap = new Map([
//     ['monitor', window.ENV.APP_SERVER_JOB_BASE_URL],
//     ['AIApp', window.ENV.APP_SERVER_AI_BASE_URL],
//   ]);
const [messageApi, contextHolder] = message.useMessage()
const instance = axios.create({
  baseURL: import.meta.env.VITE_VUE_BASE_URL,
  timeout: 2 * 60 * 1000
})

function getFileName(headers: AxiosResponseHeaders) {
  let fileName = ''
  const dispositionContent = headers['content-disposition']
  if (dispositionContent) {
    ;[, fileName] = dispositionContent.split('=')
    fileName = decodeURIComponent(fileName).replace(/"/g, '')
  }
  return fileName
}

function setToken(config: AxiosRequestConfig) {
  const userInfo = userInfoStore()
  const headers = config.headers
  if (headers && userInfo.token) {
    headers['Authorization'] = 'Bearer ' + userInfo.token
  }
}

instance.interceptors.request.use(
  function (config) {
    // if (config.url?.includes('monitor')) {
    //   config.baseURL = window.ENV.APP_SERVER_JOB_BASE_URL;
    // }

    //   setTrackId(config);
    setToken(config)
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  function (response) {
    if (response.data instanceof Blob) {
      return { blob: response.data }
    } else {
      //   const data = response.data as unknown as Response<unknown>
      const data = response.data
      if (response.status === 200 && data.code === 0) {
        return data
      } else {
        message.error(data.errorMsg || '系统未知异常')
        if (response.status === 401) {
          // token过期
          router.replace({ name: 'login' })
          return
        }
        return Promise.reject({ ...data })
      }
    }
  },
  function (error: AxiosError) {
    const errorCodeMap: Record<string, string> = {
      ERR_NETWORK: '网络错误',
      ECONNABORTED: '60s请求超时'
    }
    const message =
      (error?.code ? errorCodeMap[error.code] : error?.message) ??
      '系统未知异常'
    messageApi.error(message || '系统未知异常')
    return Promise.reject({ message })
  }
)

export default instance
export interface BaseResponse<T> {
  data: T
  code: number
  success: boolean
  errorMsg: string
  requestId: string | null
}
export function post<T, D = unknown>(
  url: string,
  data?: D,
  config?: AxiosRequestConfig<D>
): Promise<T> {
  return instance.post<T, T, D>(url, data, config)
}

export function get<T, D = unknown>(url: string, params?: D): Promise<T> {
  return instance.get<T, T, D>(url, { params })
}

export function del<T, D>(url: string, data: D): Promise<T> {
  return instance.delete<T, T, D>(url, { data })
}
