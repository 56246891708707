<template>
  <div class="failed-review">
    <div class="text progress common" v-if="!isFailed">
      <p>尊敬的用户</p>
      <p>
        您好，您注册的账号还未通过审核，请您耐心等待，也可联系您的元客数智柜员运营专员通过审核。
      </p>
    </div>
    <div class="failed common" v-else>
      <CloseCircleFilled class="error-icon" />
      <div class="text">
        <p>很抱歉，您的审核未通过！</p>
        <p>您的营业执照未上传，请上传营业执照再次提交审核。</p>
      </div>
    </div>
    <div class="qr-code-container">
      <img :src="qrCode" alt="审核人二维码" />
    </div>
    <a-button size="large" type="primary" class="big-login" @click="goLogin">
      {{ text }}
    </a-button>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { AButton } from '@/components/commons'
import { AuditStatusTypeEum } from '@/types'
import { CloseCircleFilled } from '@ant-design/icons-vue'
const route = useRoute()
const router = useRouter()
const qrCode = route.query?.code as string
const failedType = computed(
  () => route.query?.type as unknown as AuditStatusTypeEum
)
const isFailed = computed(() => failedType.value == AuditStatusTypeEum.REJECT)
const text = computed(() => (isFailed.value ? '修改注册信息' : '返回登录'))
const info = route.query?.info as unknown as string
function goLogin() {
  isFailed.value
    ? router.push(`/user/register?info=${info}`)
    : router.push('/user/login')
}
</script>

<style lang="scss">
.failed-review {
  .text {
    width: 384px;
    color: rgba(0, 0, 0, 0.85);
    font-family: 'PingFang SC';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .common {
    margin-top: 120px;
  }
  .qr-code-container {
    width: 240px;
    height: 240px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid var(--white-100, #fff);
    margin: 48px auto;
    background-color: #fff;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .failed {
    display: flex;
  }
  .error-icon {
    font-size: 40px;
    color: #f53f3f;
    align-items: start;
    margin-right: 20px;
  }
}
</style>
