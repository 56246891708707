/**
 * 账号状态
 */
export enum UserStatusEnum {
  /**
   * 启用
   */
  ENABLED = 1,
  /**
   * 禁用
   */
  DISABLED = 0
}
export interface StaffInfo {
  userId: string
  realName: string
  loginPhone: string
  enabled: UserStatusEnum
  numberOfEmployees?: number
}

/**
 * 用户类型
 *  */
export enum UserTypeEnum {
  /**运维 */
  OPU = 0,
  /**后台用户 */
  BEU,
  /**普通用户 */
  GU
}
/**账号审核状态 */
export enum AuditStatusTypeEum {
  /**审核中 */
  INPROGRESS = 0,
  /**审核通过 */
  APPROVE,
  /**审核不通过 */
  REJECT
}
export enum Sex {
  MALE = 0,
  FEMALE
}
export interface StaffListReq {
  userId: string
  realName?: string
  loginPhone?: string
  enabled?: UserStatusEnum
}
