import type { FormValidatorFunction } from '@/basic'

const phoneNumReg =
  /^1(3[0-9]|4[5-9]|5[0-3,5-9]|6[2,5,6,7]|7[0-8]|8[0-9]|9[0-3,5-9])\d{8}$/
export const phoneNumRules: FormValidatorFunction = (rule, value) => {
  return new Promise((resolve, reject) => {
    if (!value) {
      reject('请输入手机号')
    } else {
      phoneNumReg.exec(value)
        ? resolve('校验通过')
        : reject('请输入正确的手机号')
    }
  })
}
