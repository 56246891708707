// directives/vLoading.ts

import type { DirectiveBinding } from 'vue'

const LoadingDirective = {
  mounted(el: HTMLElement, binding: DirectiveBinding) {
    const loadingIndicator = document.createElement('div')
    loadingIndicator.className = 'loading-indicator'
    loadingIndicator.innerHTML = `
      <div class="spinner"></div>
    `
    el.appendChild(loadingIndicator)

    const showLoading = () => {
      el.style.position = 'relative'
      loadingIndicator.style.display = 'flex'
    }

    const hideLoading = () => {
      loadingIndicator.style.display = 'none'
    }

    if (binding.value) {
      showLoading()
    } else {
      hideLoading()
    }

    el.addEventListener('click', () => {
      if (binding.modifiers.click) {
        if (binding.value) {
          showLoading()
        } else {
          hideLoading()
        }
      }
    })
  },
  updated(el: HTMLElement, binding: DirectiveBinding) {
    const loadingIndicator = el.querySelector(
      '.loading-indicator'
    ) as HTMLElement

    if (binding.value) {
      el.style.position = 'relative'
      loadingIndicator.style.display = 'flex'
    } else {
      loadingIndicator.style.display = 'none'
    }
  }
}

export default LoadingDirective
