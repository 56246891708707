/**
 * 处理路由数据相关
 */
import type { RouteRecordRaw } from 'vue-router'
import type { PermissionType } from '@/utils/constSet'
import type { UserInfo, Menu } from '@/types'

/**
 * 判断用户是否有权限访问单个路由
 * roles：用户角色
 * route：访问的路由
 */
const hasPermission = (roles: string | PermissionType, route: any) => {
  // if (route.meta && route.meta.roles) {
  //   if (route.meta?.roles !== undefined) {
  //     return route.meta.roles.includes(roles);
  //   } else {
  //     return false;
  //   }
  // }
  return true
}
export const constantRouterComponents: { [key: string]: any } = {
  store: () => import('@/views/store/index.vue'),
  ['store/new-store']: () => import('@/views/store/components/newStore.vue'),
  ['store-info']: () => import('@/views/store/components/storeInfo.vue'),
  ['associate-cabinet']: () => import('@/views/store/components/smartvend.vue'),
  ['digital-man']: () => import('@/views/store/components/agent.vue'),
  ['associate-staff']: () => import('@/views/store/components/staff.vue'),
  smartVend: () => import('@/views/smartVend/index.vue'),
  goods: () => import('@/views/goods/index.vue'),
  asset: () => import('@/views/asset/index.vue'),
  staff: () => import('@/views/staff/index.vue'),
  scene: () => import('@/views/scene/index.vue'),
  cabinet: () => import('@/views/smartVend/index.vue')
}
const map: { [key: string]: string } = {
  store: '/store',
  smartVend: '/smartVend',
  goods: '/goods',
  asset: '/asset',
  staff: '/staff',
  scene: '/scene',
  cabinet: '/cabinet',
  ['store/detail']: '/store/detail',
  ['store/new-store']: '/store/new-store',
  ['store-info']: '/store/new-store/store-info',
  ['associate-cabinet']: '/store/new-store/associate-cabinet',
  ['digital-man']: '/store/new-store/digital-man',
  ['associate-staff']: '/store/new-store/associate-staff'
}
const routerObj = {
  path: '',
  name: '',
  meta: {
    title: '',
    hidden: false
  },
  component: ''
}
/**
 * 筛选可访问的动态路由
 * roles：用户角色
 * route：访问的动态列表
 */
export const filterAsyncRoutes = (routes: RouteRecordRaw[], roles: string) => {
  const res: RouteRecordRaw[] = []
  // routes.forEach((route) => {
  //   const r = { ...route }
  //   if (hasPermission(roles, r)) {
  //     if (r.children) {
  //       r.children = filterAsyncRoutes(r.children, roles)
  //     }
  //     res.push(r)
  //   }
  // })
  // console.log(res)
  // return res
}
export function generatorRouter(routes: Menu[]): RouteRecordRaw[] {
  return routes.map((route) => {
    const obj: RouteRecordRaw = {
      path: map[route.menuUrl],
      meta: {
        title: route.menuName,
        hidden: route.isShow ? false : true,
        hideChildrenInMenu: true
      },
      component: constantRouterComponents[route.menuUrl],
      children: []
    }
    if (route.children && route.children.length > 0) {
      obj.children = generatorRouter(route.children)
    }
    return obj
  })
}
