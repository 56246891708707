<template>
  <div class="login">
    <a-tabs class="tabs" default-active-key="PasswordLogin">
      <!-- <a-tab-pane key="PasswordLogin" tab="账户登录">
        <PasswordLogin v-model:is-validate="isValidate"
      /></a-tab-pane> -->
      <a-tab-pane key="SMSLogin" force-render tab="手机号登录">
        <SMSLogin v-model:is-validate="isValidate" ref="smsRef" />
      </a-tab-pane>
    </a-tabs>
    <div class="login-setting">
      <!-- <div class="handle-password">
        <a-checkbox v-model="rememberMe">自动登录</a-checkbox>
        <a-button type="link" @click="findPassword">忘记密码？</a-button>
      </div> -->
      <div class="safety">
        <a-checkbox v-model:checked="agree" />
        <span
          >已阅读并接受元客智播平台 <a href="">服务协议</a> 与
          <a href="">隐私协议</a></span
        >
      </div>
      <a-button
        size="large"
        type="primary"
        htmlType="submit"
        class="big-login"
        :class="{ disabled: !isValidate }"
        :loading="state.loginBtn"
        :disabled="!isValidate"
        @click="onLogin"
        >登录</a-button
      >
      <a-button type="link" class="big-register" @click="onRegister"
        >注册企业管理员</a-button
      >
      <contextHolder />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import PasswordLogin from './components/PasswordLogin.vue'
import SMSLogin from './components/SMSLogin.vue'
import ScanCodeLogin from './components/ScanCodeLogin.vue'
import { useRouter } from 'vue-router'
// import { Tabs as ATabs } from 'ant-design-vue';
import {
  ATabs,
  ATabPane,
  AButton,
  ACheckbox,
  message
} from '@/components/commons'

type PhoneNum = string
type Account = string
type Password = string
type LoginInfoType = {
  phoneNum: PhoneNum
  account: Account
  password: Password
}

const [messageApi, contextHolder] = message.useMessage()
const smsRef = ref()
const isValidate = ref(false)
const state = ref({
  loginBtn: false
})
const agree = ref(false)
const router = useRouter()
const isApproved = ref(false)
function findPassword() {
  router.push({ name: 'findPassword' })
}
function onRegister() {
  router.push('/user/register')
}
async function onLogin() {
  if (!agree.value) {
    return messageApi.warning('请先阅读并同意服务协议与隐私协议')
  }
  state.value.loginBtn = true
  try {
    const res = await smsRef.value?.onLogin()
  } catch (error) {}
  state.value.loginBtn = false
}
</script>

<style lang="scss">
.login {
  width: 384px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  .login-title {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
    .logo {
      height: 45px;
      margin-right: 20px;
    }
    .title-text {
      font: 32px/47px 'SourceHanSansSC-regular';
      color: #232424;
    }
  }
  .sub-title {
    margin-bottom: 42px;
    font: 14px/20px 'SourceHanSansSC-regular';
    color: #87898b;
  }

  .login-setting {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    font: 14px/20px 'SourceHanSansSC-regular';
    .handle-password {
      margin-top: 72px;
    }
    .big-login {
      margin-top: 18px;
    }
  }
}
.login {
  .tabs {
    width: 100%;
    .ant-tabs-bar .ant-tabs-top-bar {
      border-bottom: 0px !important;
    }
    .ant-tabs-ink-bar {
      display: none !important;
    }
    .ant-tabs-nav {
      &::before {
        border: none;
      }
    }
    .ant-tabs-nav .ant-tabs-tab-active {
      position: relative;
    }
    .ant-tabs-nav .ant-tabs-tab {
      font-size: 21px;
      padding: 0 0 8px;
      margin-right: 40px;
      &-btn {
        color: #00000073;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
      }
    }
    .ant-tabs-bar {
      margin-bottom: 48px;
      border-bottom: 0px !important;
    }
    .ant-tabs-nav .ant-tabs-tab-active::after {
      width: 48px;
      height: 4px;
      border-radius: 1px;
      background: var(--Brandblue-6, #165dff);
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      content: '';
    }
    input {
      -webkit-text-fill-color: initial !important;
      background-color: rgb(255, 255, 255) !important;
      background-image: none !important;
      color: initial !important;
    }
  }

  .big-register {
    color: #165dff;
    text-align: center;
    font-family: 'PingFang SC';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 88px;
  }
}
</style>
