import { defineStore } from 'pinia'
import { PermissionType } from '@/utils/constSet'
import { setStorage, getStorage, clearStorage } from '@/utils/storage'
import type { RouteRecordRaw } from 'vue-router'
import { generatorRouter } from '@/router/handler'
import router, { publicRoutes } from '@/router'
import type { UserInfo } from '@/types'

interface UserStoreType {
  userName: string // 用户名
  token: string // token
  permission: PermissionType // 权限
  routes: RouteRecordRaw[] // 静态路由
  dynamicRoutes: RouteRecordRaw[] // 动态路由
  info: UserInfo | null
}

export interface MenuRender {
  title: string
  path: string
  meta: any
  children?: MenuRender[]
}

const formateMenu = (routes: RouteRecordRaw[], role: PermissionType) => {
  const itemMenu: MenuRender[] = []

  routes.map((item) => {
    if (item?.meta && Object.keys(item.meta).length > 0) {
      if (item?.children && item.children.length > 0) {
        itemMenu.push({
          title: (item.meta.title as string) || '-',
          meta: item.meta,
          path: item.path,
          children: formateMenu(item.children, role)
        })
        return
      }

      itemMenu.push({
        title: (item.meta.title as string) || '-',
        path: item.path,
        meta: item.meta
      })
    }
  })

  return itemMenu
}

export const userInfoStore = defineStore({
  id: 'useInfo',
  state: (): UserStoreType => ({
    userName: getStorage('userName') || '', // 用户名
    permission:
      (getStorage('userPermission') as PermissionType) || PermissionType.guest, // 权限
    routes: [], // 静态路由
    dynamicRoutes: [], // 动态路由
    token: '',
    info: {} as UserInfo
  }),
  persist: {
    pick: ['userName', 'token', 'info']
  },
  getters: {
    menu(state) {
      return formateMenu(state.routes, state.permission)
    }
  },
  actions: {
    // 设置用户信息
    setUserInfo(data: UserInfo) {
      const { userName, token } = data
      this.info = data
      this.userName = userName
      setStorage('userName', userName)
      console.log('token', token)
      this.token = token
      const userPermission = PermissionType.admin
      setStorage('userPermission', userPermission)
      this.permission = userPermission
    },
    // 清除用户信息
    clearUserInfo() {
      this.userName = ''
      this.token = ''
      this.info = null
      // this.permission = PermissionType.guest
      clearStorage()
    },
    // 构建路由
    generateRoutes(cb?: any) {
      // accessedRoutes: 筛选出的动态路由
      if (this.info) {
        const accessedRoutes = generatorRouter(this.info.menuDetailVoList)
        publicRoutes[2].children = accessedRoutes
        this.dynamicRoutes.push(publicRoutes[2])
        this.dynamicRoutes.forEach((route) => {
          router.addRoute(route)
        })
      }
      cb?.()
    }
  }
})
